.ticker-tape-container {
    overflow-x: hidden;
    width: 100%;
    display: flex;
  }
  
  .ticker-tape {
    --direction: normal;
    --duration: 60s;
    --delay: 0s;
    --iteration-count: infinite;
    display: flex;
    gap: 1rem;
    flex: 0 0 auto;
    margin-right: 1rem;
    min-width: 100%;
    align-items: center;
    animation: scrmarqueeoll var(--duration) linear var(--delay) var(--iteration-count);
    animation-play-state: var(--play);
    animation-delay: var(--delay);
    animation-direction: var(--direction);
  }
  
  @keyframes scrmarqueeoll {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  /* This is just some styling to make it look presentable */
  
  .basic-styling {
    margin: 12px 0;
  }
  
  .basic-styling span {
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
  }
  